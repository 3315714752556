import { useState } from 'react';
import cn from 'classnames';
// UI2
import Icon from 'ui/Icon/Icon';

import styles from './Accordion.module.scss';

type AccordionProps = {
  title: string;
  titleClassName?: string;
  className?: string;
  openedByDefault?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  titleClassName,
  className,
  openedByDefault = true,
  children,
}) => {
  const [open, setOpen] = useState(openedByDefault);

  const handleAccordionToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={cn(styles.root, className)}>
      <div>
        <h3>
          <button
            className={styles.toggleButton}
            onClick={handleAccordionToggle}
            id="accordion-header-1"
            aria-expanded={open}
            aria-controls="accordion-panel-1"
          >
            <span className={cn(styles.title, titleClassName)}>{title}</span>
            <Icon
              name={open ? 'chevron-up' : 'chevron-down'}
              className={styles.icon}
            />
          </button>
        </h3>

        {open && (
          <section id="accordion-panel-1" aria-labelledby="accordion-header-1">
            {children}
          </section>
        )}
      </div>
    </div>
  );
};

export default Accordion;
