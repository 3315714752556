import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Types
import { GetStreams_streamsV2_entities } from 'api/streams/types/GetStreams';
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
import { GetAllHomePageData_getHomePageData_watchStreams } from 'api/homePage/types/GetAllHomePageData';
import { GetStoreStreams_getStoreStreams_entities } from 'api/streams/types/GetStoreStreams';
import { StreamStatus } from 'api/graphql-global-types';
// Helpers
import {
  getStreamLink,
  getStreamerName,
  getPublicStreamDate,
} from 'helpers/streams';
// Ui
import LinkButton from 'ui2/LinkButton/LinkButton';
import Image from 'ui/Image/Image';
import EllipsisText from 'ui2/EllipsisText/EllipsisText';
// Styles
import styles from './FeaturedStream.module.scss';

type Stream =
  | GetStreams_streamsV2_entities
  | GetAllHomePageData_getHomePageData_watchStreams
  | GetAthleteStreams_liveAndUpcomingStreams_entities
  | GetAthleteStreams_pastStreams_entities
  | GetStoreStreams_getStoreStreams_entities;

type FeaturedStreamProps = {
  stream?: Stream;
  loading?: boolean;
  linkText: string;
  onLinkClick?: (
    data: Stream,
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => void;
};

const FeaturedStream = ({
  stream,
  loading,
  linkText,
  onLinkClick,
}: FeaturedStreamProps) => {
  const { push } = useRouter();

  if (!stream) {
    return null;
  }

  const streamLink = getStreamLink(stream);
  const streamerName = getStreamerName(stream.store);
  const streamDate = getPublicStreamDate(stream.scheduleDate, stream.timeZone);
  const isEnded = stream.streamStatus === StreamStatus.Ended;

  const handleLinkClick: FeaturedStreamProps['onLinkClick'] = (data, e) => {
    if (onLinkClick) {
      onLinkClick(data, e);
    }
  };

  const handleReadMoreClick = () => {
    push(streamLink);
  };

  return (
    <div aria-label="featured stream card" className={styles.root}>
      {isEnded && <span className={styles.pastLabel}>Past</span>}

      <div className={styles.imageWrapper}>
        {stream.imageURL && (
          <Link href={streamLink} prefetch={false}>
            <a>
              <Image
                src={stream.imageURL}
                alt={stream.name || ''}
                placeholder="blur"
                sizes="(max-width: 991px) 100vw, 50vw"
              />
            </a>
          </Link>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <div>
            <p className={styles.meta}>
              {stream.store?.slug ? (
                <Link href={`/${stream.store.slug}`} prefetch={false}>
                  <a className={styles.metaName}>{streamerName}</a>
                </Link>
              ) : (
                <span className={styles.metaName}>{streamerName}</span>
              )}
              {streamDate && <span>&nbsp;&bull;&nbsp;{streamDate}</span>}
            </p>
            <h3 className={styles.title}>{stream.name}</h3>
            <EllipsisText
              className={styles.description}
              text={stream.description}
              maxLine={{ xs: 4, ml: 3 }}
              onReadMoreClick={handleReadMoreClick}
            />
          </div>
          <LinkButton
            color="black"
            href={streamLink}
            onClick={(e) => handleLinkClick(stream, e)}
            loading={loading}
            xs="small"
            l="default"
          >
            {linkText}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default FeaturedStream;
