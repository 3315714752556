import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
} from '@apollo/client';
// Api
import { GET_EXPERIENCES_MINIMAL_INFO } from 'api/experiences/queries';
// Types
import {
  GetExperiencesMinimalInfo,
  GetExperiencesMinimalInfoVariables,
} from 'api/experiences/types/GetExperiencesMinimalInfo';
import { ExperienceStatus } from 'api/graphql-global-types';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type UseGetAthleteExperienceOptions = {
  options?: BasicQueryOptions;
  variables?: GetExperiencesMinimalInfoVariables;
  skip?: boolean;
};

export const useGetExperience = (
  options?: UseGetAthleteExperienceOptions
): QueryResult<
  GetExperiencesMinimalInfo,
  GetExperiencesMinimalInfoVariables
> => {
  return useQuery<
    GetExperiencesMinimalInfo,
    GetExperiencesMinimalInfoVariables
  >(GET_EXPERIENCES_MINIMAL_INFO, {
    variables: {
      input: {
        ...options?.variables?.input,
        storeIds: options?.variables?.input.storeIds?.length
          ? options?.variables?.input.storeIds
          : [],
        statuses: [ExperienceStatus.Active],
      },
    },
    skip: options?.skip,
    fetchPolicy: 'network-only',
  });
};
