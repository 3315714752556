import { useRouter } from 'next/router';
// Types
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import {
  MemorabiliaOrderBy,
  MemorabiliaProductType,
  MemorabiliaStatus,
} from 'api/graphql-global-types';
// Helpers
import { getCheapestActiveAma } from 'helpers/ama';
import { parseToPlainText } from 'helpers/textEditor';
// Hooks
import { useGetAthleteMemorabilia } from 'hooks';
// Layouts
import Head from 'layouts/Head/Head';
import Header from 'layouts/Header/Header';
import Main from 'layouts/Main/Main';
import Footer from 'layouts/Footer/Footer';
// Components
import Section from 'components/common2/Section/Section';
import Ama from '../Ama/Ama';
import NewsLetter from 'components/common2/NewsLetter/NewsLetter';
import StreamsPendingStore from '../components/StreamsPendingStore/StreamsPendingStore';
import Description from '../Description/Description';
import Memorabilia from '../Memorabilia/Memorabilia';
import RelatedExperience from 'components/Experience/components/RelatedExperience/RelatedExperience';
import RelatedProductsListPendingStore from 'components/Athlete/components/RelatedProductsListPendingStore/RelatedProductsListPendingStore';
import RelatedMediaFeed from 'components/MediaFeed/RelatedMediaFeed/RelatedMediaFeed';
// Styles
import styles from './PendingAthleteContainer.module.scss';

type PendingAthleteContainerProps = {
  store?: GetStoreBySlug_store;
};

const PendingAthleteContainer = ({ store }: PendingAthleteContainerProps) => {
  const router = useRouter();
  const athleteSlug = router.query.athleteSlug;

  const { data } = useGetAthleteMemorabilia({
    storeId: store?.id || '',
    variables: {
      productTypes: [MemorabiliaProductType.Memorabilia],
      statuses: [MemorabiliaStatus.Active],
      limit: 1,
      orderBy: MemorabiliaOrderBy.price,
    },
    skip: !store || !store.hasMemorabilia,
  });

  const memorabilia = data?.getMemorabilia?.entities?.[0];

  if (!store) {
    return null;
  }

  const name = store?.storeDetails?.storeName || '';
  const description =
    store?.storeDetails?.description || store?.storeDetails?.bio || '';
  const metaTitle = `${name}: Streams, Merchandise, Personal Videos | MILLIONS`;
  const metaDescription = `${parseToPlainText(
    description
  )} ${name}: Watch live streams, buy ${name} merch, ask questions, and get answered on MILLIONS.`;
  const avatarUrl = store?.storeDetails?.avatarURL || '';
  const cheapestActiveAma = getCheapestActiveAma(store?.amas || []);

  const shouldShowMemorabiliaSection = store.hasMemorabilia;
  const hasMerchProducts =
    store.hasMerch || store.hasMemorabilia || store.hasPureProducts;

  // note that current pending stores clicking on any of the links below will go to app home page, but code here reflects code on regular athletes in case pending functionality changes in the future
  const seeMerchLink = `${athleteSlug}/merch`;
  const seeMemorabiliaLink = `${athleteSlug}/memorabilia`;
  const seeProductLink = `${athleteSlug}/products`;
  const seeExperienceLink = `${athleteSlug}/experiences`;
  const seeStreamsLink = `${athleteSlug}/streams`;
  const seeMediaLink = `${athleteSlug}/media`;

  const isNotEmpty =
    shouldShowMemorabiliaSection ||
    hasMerchProducts ||
    store.hasExperiences ||
    store.hasStreams ||
    store.hasMediaPost;

  return (
    <>
      <Head title={metaTitle} description={metaDescription} image={avatarUrl} />

      <Header />

      <Main
        backgroundColor="white"
        className={styles.athleteContainer}
        withoutSidesPadding
      >
        <Description store={store} pending />
        {isNotEmpty && (
          <>
            {shouldShowMemorabiliaSection && (
              <Memorabilia
                store={store}
                memorabilia={memorabilia}
                isPage={false}
              />
            )}

            {store.hasMerch && (
              <Section
                title=""
                backgroundTitle="Merch"
                seeAllLink={seeMerchLink}
                seeAllLinkTitle="view more"
                className={styles.adjustedPosition}
              >
                <RelatedProductsListPendingStore
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  title="Merch"
                  shouldHideMostExpensiveItem
                  showOnlyMerch
                />
              </Section>
            )}

            {store.hasMemorabilia && (
              <Section
                title=""
                backgroundTitle="Memorabilia"
                seeAllLink={seeMemorabiliaLink}
                seeAllLinkTitle="view more"
                className={styles.adjustedPosition}
              >
                <RelatedProductsListPendingStore
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  title="Memorabilia"
                  shouldHideMostExpensiveItem
                  showOnlyMemorabilia
                />
              </Section>
            )}

            <Ama ama={cheapestActiveAma} store={store} />

            {store.hasExperiences && (
              <Section
                title=""
                backgroundTitle="Experiences"
                seeAllLink={seeExperienceLink}
                seeAllLinkTitle="view more"
                className={styles.adjustedPosition}
              >
                <RelatedProductsListPendingStore
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  title="Experiences"
                  shouldHideMostExpensiveItem
                  showOnlyExperience
                />
              </Section>
            )}

            <RelatedExperience
              store={store}
              className={styles.adjustedPosition}
              link={seeExperienceLink}
            />

            {store.hasPureProducts && (
              <Section
                title=""
                backgroundTitle="Products"
                seeAllLink={seeProductLink}
                seeAllLinkTitle="view more"
                className={styles.adjustedPosition}
              >
                <RelatedProductsListPendingStore
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  title="Products"
                  shouldHideMostExpensiveItem
                  showOnlyProducts
                />
              </Section>
            )}

            <StreamsPendingStore
              storeId={store.id}
              storeSlug={store.slug}
              storeRole={store.role}
              className={styles.adjustedPosition}
              link={seeStreamsLink}
            />

            <RelatedMediaFeed
              store={store}
              className={styles.adjustedPosition}
              link={seeMediaLink}
            />
          </>
        )}

        <NewsLetter />
      </Main>

      <Footer />
    </>
  );
};

export default PendingAthleteContainer;
