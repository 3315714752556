import cn from 'classnames';
import Link from 'next/link';
// Types
import { GetAllHomePageData_getHomePageData_merch } from 'api/homePage/types/GetAllHomePageData';
import { GetMerchProducts_getMerchProducts_entities } from 'api/merch/types/GetMerchProducts';
import { GetMerchMinimalInfo_getMerchProducts_entities } from 'api/merch/types/GetMerchMinimalInfo';
// Helpers
import { formatCurrencyStringWithoutName } from 'helpers/formatCurrencyString';
import { computeMerchProductPath } from 'helpers/routes';
import { getStreamerName } from 'helpers/streams';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './RelatedMerchItem.module.scss';

// "default" type for Home & List pages,
// "ad" - for Stream page
// "related" - for Store & Merch pages,
export type TypeMerchListItem = 'default' | 'ad' | 'related';

type MerchListItemProps = {
  merch:
    | GetAllHomePageData_getHomePageData_merch
    | GetMerchProducts_getMerchProducts_entities
    | GetMerchMinimalInfo_getMerchProducts_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  type: TypeMerchListItem;
  className?: string;
  theme?: Theme;
};

const MerchListItem = ({
  merch,
  imageLazyLoading,
  openInNewTab,
  type,
  className,
  theme = 'white',
}: MerchListItemProps) => {
  const isDefault = type === 'default';
  const isAd = type === 'ad';
  const isRelated = type === 'related';

  const { title, mainImageUrl, store } = merch;

  const price = formatCurrencyStringWithoutName(merch.minPrice);
  const link = computeMerchProductPath(merch);
  const storeName = getStreamerName(store);

  return (
    <Link href={link} prefetch={false}>
      <a
        className={cn(
          styles.root,
          {
            [styles.default]: isDefault,
            [styles.ad]: isAd,
            [styles.related]: isRelated,
            [styles[`theme-${theme}`]]: theme,
          },
          className
        )}
        title={`"${title}" by ${storeName}`}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <div className={styles.imageWrapper}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>{price}</div>
          </div>
          <Image
            className={styles.image}
            src={mainImageUrl || ''}
            alt={title}
            priority={!imageLazyLoading}
            placeholder="blur"
            sizes="25vw"
          />
        </div>

        <div className={styles.title}>{title}</div>
      </a>
    </Link>
  );
};

export default MerchListItem;
