import cn from 'classnames';
import Link from 'next/link';
// Types
import { GetMemorabilia_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabilia';
import { GetMemorabiliaMinimalInfo_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
// Helpers
import { formatCurrencyStringWithoutName } from 'helpers/formatCurrencyString';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './RelatedMemorabiliaItem.module.scss';

export type TypeListItem = 'default' | 'ad' | 'related';

type MemorabiliaListItemProps = {
  memorabilia:
    | GetMemorabilia_getMemorabilia_entities
    | GetMemorabiliaMinimalInfo_getMemorabilia_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  itemType: TypeListItem;
  className?: string;
  theme?: Theme;
};

const MemorabiliaListItem = ({
  memorabilia,
  openInNewTab,
  itemType,
  className,
  theme = 'white',
}: MemorabiliaListItemProps) => {
  const isDefault = itemType === 'default';
  const isAd = itemType === 'ad';
  const isRelated = itemType === 'related';

  const price = formatCurrencyStringWithoutName(memorabilia.price);
  const link = `/${memorabilia?.store?.slug}/memorabilia/${memorabilia.slug}`;
  const storeName = memorabilia?.store?.storeDetails?.storeName || '';

  const mainImage = memorabilia?.images?.find((item) => item.isMainImage);

  return (
    <Link href={link || ''} prefetch={false}>
      <a
        className={cn(
          styles.root,
          {
            [styles.default]: isDefault,
            [styles.ad]: isAd,
            [styles.related]: isRelated,
            [styles[`theme-${theme}`]]: theme,
          },
          className
        )}
        title={`${memorabilia.title} by ${storeName}`}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <div className={styles.imageWrapper}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>{price}</div>
          </div>

          <Image
            className={styles.productImage}
            src={mainImage?.url || ''}
            alt={memorabilia.title}
            priority
            sizes="25vw"
          />
        </div>

        <div className={styles.title}>{memorabilia.title}</div>
      </a>
    </Link>
  );
};

export default MemorabiliaListItem;
