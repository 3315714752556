import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';
// Types
import { GetExperiences_getExperiences_entities } from 'api/experiences/types/GetExperiences';
// Constants
import { ATHLETES } from 'constants/routes';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './ExperienceListItem.module.scss';

type ExperienceListItemProps = {
  experience: GetExperiences_getExperiences_entities;
  type?: 'list' | 'carousel' | 'store' | 'default' | 'ad' | 'related';
  priorityImageLoading?: boolean;
  theme?: Theme;
};

const ExperienceListItem = ({
  experience,
  type = 'list',
  theme = 'white',
  priorityImageLoading,
}: ExperienceListItemProps) => {
  const router = useRouter();

  const isDefault = type === 'default';
  const isAd = type === 'ad';
  const isRelated = type === 'related';

  const price = formatCurrencyString(experience.price);
  const link = `/${experience?.store?.slug}/experience/${experience.slug}`;
  const storeName = experience?.store?.storeDetails?.storeName || '';

  const handleStoreButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const url = experience.store?.slug
      ? `/${experience.store?.slug}`
      : ATHLETES;

    router.push(url);
  };

  const mainImage = experience?.images?.find((item) => item.isMainImage);

  return (
    <Link href={link || ''} prefetch={false}>
      <a
        className={cn(styles.root, {
          [styles.default]: isDefault,
          [styles.ad]: isAd,
          [styles.related]: isRelated,
          [styles[`theme-${theme}`]]: theme,
        })}
        title={`${experience.title} by ${storeName}`}
      >
        <div className={cn(styles.imageWrapper)}>
          <div
            className={cn(styles.experienceBannerWrapper, {
              [styles.fullTitle]: experience.numberOfUnits === null,
            })}
          >
            <div className={styles.experienceBanner}>
              <p className={styles.experienceTitle}>Experience</p>
            </div>
            <div
              className={cn(styles.numberAvailable, {
                [styles.unlimited]: experience.numberOfUnits === null,
              })}
            >
              <p className={styles.numberOfUnitsText}>
                {`${experience.numberOfUnits} available`}
              </p>
            </div>
          </div>
          <Image
            src={mainImage?.experienceImageFileKey || ''}
            alt={experience.title}
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            priority={priorityImageLoading}
            sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
          />
        </div>

        <div className={styles.infoWrapper}>
          {isDefault && (
            <button
              className={styles.storeButton}
              onClick={handleStoreButtonClick}
            >
              {storeName}
            </button>
          )}
          <p className={cn(styles.title, { [styles.large]: !isAd })}>
            {experience.title}
          </p>

          <div className={styles.infoFooter}>
            {isAd && (
              <>
                <div className={styles.infoFooterWrapper}>
                  <p className={cn(styles.price, { [styles.large]: !isAd })}>
                    {price}
                  </p>
                </div>
                <p className={styles.cta}>Buy Now</p>
              </>
            )}

            {!isAd && <p className={styles.viewMoreLabel}>view - {price}</p>}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ExperienceListItem;
