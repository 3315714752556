// UI2
import Accordion from 'ui2/Accordion/Accordion';

import styles from './HowAmaWorks.module.scss';

type HowAmaWorksProps = {
  className?: string;
  openedByDefault?: boolean;
};

const HowAmaWorks = ({
  className,
  openedByDefault = true,
}: HowAmaWorksProps) => {
  return (
    <Accordion
      title="How it Works"
      className={className}
      openedByDefault={openedByDefault}
    >
      <p className={styles.text}>
        Request an authentic one-on-one with your favorite athlete and gain
        their insights via short form personal video about event predictions,
        training tips, their stories, or anything you want to ask about.
        They&apos;re here to answer your questions!
      </p>
      <div className={styles.steps}>
        <div className={styles.step}>
          <span className={styles.stepNumber}>1</span>
          <p className={styles.stepDescription}>
            Select if the personal video is for yourself or someone else.
          </p>
        </div>
        <div className={styles.step}>
          <span className={styles.stepNumber}>2</span>
          <p className={styles.stepDescription}>
            Enter the name you&apos;d like the athlete to mention.
          </p>
        </div>
        <div className={styles.step}>
          <span className={styles.stepNumber}>3</span>
          <p className={styles.stepDescription}>
            Enter the question you&apos;d like to ask the athlete. Stay tuned
            for a video response!
          </p>
        </div>
      </div>
    </Accordion>
  );
};

export default HowAmaWorks;
