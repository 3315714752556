// Types
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import {
  GetStoreBySlug_store,
  GetStoreBySlug_store_amas,
} from 'api/store/types/GetStoreBySlug';
import { GetAllAmas_amas_entities } from 'api/ama/types/GetAllAmas';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
// Constants
import { ORDER_AMA } from 'constants/routes';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import { trackAddToCartAma } from 'helpers/metrics';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui2
import BackgroundTitle from 'ui2/BackgroundTitle/BackgroundTitle';
import LinkButton from 'ui2/LinkButton/LinkButton';
// Components
import AmaListItem from 'components/common2/AmaList/components/AmaListItem/AmaListItem';
import HowAmaWorks from 'components/common2/HowAmaWorks/HowAmaWorks';
// Styles
import styles from './Ama.module.scss';

type AmaProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  ama: GetStoreBySlug_store_amas | undefined;
};

const Ama = ({ ama, store }: AmaProps) => {
  if (!ama) {
    return null;
  }

  const amaPrice = Number(ama?.price || 0);
  const storeSlug = store?.slug || '';
  const listItemData = {
    ...ama,
    store,
  };

  const handleLinkClick = () => {
    const id = ama?.id || '';
    const type = ama?.type || 'Ama';
    const storeName = store?.storeDetails?.storeName || '';

    trackAddToCartAma({
      id,
      price: amaPrice,
      type,
      slug: storeSlug,
      storeName,
    });
  };

  const amaVideoBlock = (
    <div className={styles.amaVideo}>
      <AmaListItem
        ama={listItemData as GetAllAmas_amas_entities}
        type="store"
      />
    </div>
  );

  return (
    <section className={styles.root}>
      <BackgroundTitle content="Personal video" />
      <ResolutionWrapper className={styles.resolutionWrapper}>
        <div className={styles.mainBlock}>
          <h2 className={styles.title}>Personal video</h2>

          <div className={styles.mobileAmaBlock}>{amaVideoBlock}</div>

          <div className={styles.body}>
            <p className={styles.description}>
              I&apos;m here to answer your questions about training, about my
              journey, my predictions on the next big event, or anything you
              want to know. Hit me up and ask me anything!
            </p>

            <HowAmaWorks className={styles.howAmaWorks} />
          </div>

          <LinkButton
            href={`${ORDER_AMA}/${storeSlug}`}
            onClick={handleLinkClick}
            color="black"
            className={styles.linkButton}
            xs="small"
            l="default"
          >
            Book a Personalized Video - {formatCurrencyString(amaPrice)}
          </LinkButton>
        </div>
        <div className={styles.desktopAmaBlock}>{amaVideoBlock}</div>
      </ResolutionWrapper>
    </section>
  );
};

export default Ama;
