import Link from 'next/link';
import cn from 'classnames';
// Types
import { GetExperiences_getExperiences_entities } from 'api/experiences/types/GetExperiences';
import { GetExperiencesMinimalInfo_getExperiences_entities } from 'api/experiences/types/GetExperiencesMinimalInfo';
// Helpers
import { formatCurrencyStringWithoutName } from 'helpers/formatCurrencyString';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './RelatedExperienceItem.module.scss';

type TypeListItem = 'default' | 'ad' | 'related';

type ExperienceListItemProps = {
  experience:
    | GetExperiences_getExperiences_entities
    | GetExperiencesMinimalInfo_getExperiences_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  itemType: TypeListItem;
  className?: string;
  theme?: Theme;
};

const ExperienceListItem = ({
  experience,
  openInNewTab,
  itemType,
  className,
  theme = 'white',
}: ExperienceListItemProps) => {
  const isDefault = itemType === 'default';
  const isAd = itemType === 'ad';
  const isRelated = itemType === 'related';

  const price = formatCurrencyStringWithoutName(experience.price);
  const link = `/${experience?.store?.slug}/experience/${experience.slug}`;
  const storeName = experience?.store?.storeDetails?.storeName || '';

  const mainImage = experience?.images?.find((item) => item.isMainImage);

  return (
    <Link href={link || ''} prefetch={false}>
      <a
        className={cn(styles.root, {
          [styles.default]: isDefault,
          [styles.ad]: isAd,
          [styles.related]: isRelated,
          [styles[`theme-${theme}`]]: theme,
          className,
        })}
        title={`${experience.title} by ${storeName}`}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <div className={styles.imageWrapper}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>{price}</div>
          </div>
          <Image
            className={styles.experienceImage}
            src={mainImage?.experienceImageFileKey || ''}
            alt={experience.title}
            priority
            sizes="25vw"
          />
        </div>
        <div className={styles.title}>{experience.title}</div>
      </a>
    </Link>
  );
};

export default ExperienceListItem;
