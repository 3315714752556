// Types
import {
  GetExperienceBySlug,
  GetExperienceBySlug_getExperienceBySlug_store,
} from 'api/experiences/types/GetExperienceBySlug';
import { StoreStatus, UserRole } from 'api/graphql-global-types';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// Hooks
import { useGetExperience } from 'hooks/useGetExperiences';
// Constants
import { EXPERIENCES } from 'constants/routes';
// Components
import Section from 'components/common2/Section/Section';
import ExperienceList from 'components/common2/ExperienceList/ExperienceList';

type RelatedExperienceProps = {
  currentExperience?: GetExperienceBySlug;
  store: GetStoreBySlug_store | GetExperienceBySlug_getExperienceBySlug_store;
  showPendingStoreItems?: boolean;
  className?: string;
  link?: string;
};

const RelatedExperience = ({
  store,
  currentExperience,
  showPendingStoreItems = false,
  className,
  link,
}: RelatedExperienceProps) => {
  const storeId = store?.id || '';

  const { data } = useGetExperience({
    variables: {
      input: {
        storeIds: [storeId],
        storeStatuses: [
          StoreStatus.Active,
          ...(showPendingStoreItems ? [StoreStatus.Pending] : []),
        ],
        limit: 8,
      },
    },
  });

  const isAthlete = store?.role === UserRole.Athlete;
  const isOrganization = store?.role === UserRole.Organization;
  const isContentCreator = store?.role === UserRole.ContentCreator;

  const currentExperienceId =
    currentExperience && 'getExperienceBySlug' in currentExperience
      ? currentExperience?.getExperienceBySlug?.id
      : '';

  const experience =
    data?.getExperiences?.entities?.filter(
      ({ id }) => id !== currentExperienceId
    ) || [];

  const pageUrl =
    link ??
    `${EXPERIENCES}?${
      isAthlete
        ? 'athleteIds'
        : isOrganization
        ? 'organizationIds'
        : isContentCreator
        ? 'contentCreatorIds'
        : ''
    }=${storeId}`;

  if (!experience.length) {
    return null;
  }

  return (
    <Section
      title="Experiences"
      backgroundTitle="Experiences"
      seeAllLink={pageUrl}
      seeAllLinkTitle={`View all ${store?.storeDetails?.storeName} experiences`}
      className={className}
    >
      <ExperienceList experiences={experience?.splice(0, 3)} itemType="list" />
    </Section>
  );
};

export default RelatedExperience;
