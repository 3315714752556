import { useQuery } from '@apollo/client';
// Api
import { GET_STORE_MERCH_PRODUCTS } from 'api/merch/queries';
import { GET_STORE_MEMORABILIA } from 'api/memorabilia/queries';
// Hooks
import { useGetExperience } from 'hooks/useGetExperiences';
// Types
import {
  MemorabiliaProductType,
  MerchProductOrderBy,
  StoreStatus,
} from 'api/graphql-global-types';
import {
  GetStoreMerchProducts,
  GetStoreMerchProductsVariables,
} from 'api/merch/types/GetStoreMerchProducts';
import {
  GetStoreMemorabilia,
  GetStoreMemorabiliaVariables,
} from 'api/memorabilia/types/GetStoreMemorabilia';
// Ui2
import Loader from 'ui2/Loader/Loader';
// Components
import RelatedMemorabiliaItem from '../RelatedProductsList/components/RelatedMemorabiliaItem/RelatedMemorabiliaItem';
import RelatedMerchItem from '../RelatedProductsList/components/RelatedMerchItem/RelatedMerchItem';
import RelatedExperienceItem from '../RelatedProductsList/components/RelatedExperienceItem/RelatedExperienceItem';
import CarouselWithArrows from 'components/HomePage/components/common/CarouselWithArrows/CarouselWithArrows';
// Styles
import styles from './RelatedProductsListPendingStore.module.scss';

type RelatedProductsListPendingStoreProps = {
  storeId: string;
  theme?: Theme;
  title?: string;
  className?: string;
  shouldHideMostExpensiveItem?: boolean;
  hideArrowCount?: number;
  showOnlyMerch?: boolean;
  showOnlyMemorabilia?: boolean;
  showOnlyProducts?: boolean;
  showOnlyExperience?: boolean;
};

const RelatedProductsListPendingStore = ({
  storeId,
  theme = 'white',
  title,
  className,
  shouldHideMostExpensiveItem = false,
  hideArrowCount = 5,
  showOnlyMerch = false,
  showOnlyMemorabilia = false,
  showOnlyProducts = false,
  showOnlyExperience = false,
}: RelatedProductsListPendingStoreProps) => {
  const { data: merchData, loading: merchLoading } = useQuery<
    GetStoreMerchProducts,
    GetStoreMerchProductsVariables
  >(GET_STORE_MERCH_PRODUCTS, {
    variables: {
      input: {
        orderBy: MerchProductOrderBy.Date,
        storeId: storeId,
      },
    },
  });

  const { data: memorabiliaData, loading: memorabiliaLoading } = useQuery<
    GetStoreMemorabilia,
    GetStoreMemorabiliaVariables
  >(GET_STORE_MEMORABILIA, {
    variables: {
      input: {
        storeId: storeId,
        limit: 100,
      },
    },
  });

  const { data: experienceData, loading: experienceLoading } = useGetExperience(
    {
      variables: {
        input: {
          storeIds: [storeId],
          storeStatuses: [StoreStatus.Active],
        },
      },
    }
  );

  const merch = merchData?.getStoreMerchProducts.entities || [];

  const mostExpensiveItemId = memorabiliaData?.getStoreMemorabilia?.entities
    ?.filter(
      ({ memorabiliaProductType }) =>
        memorabiliaProductType === MemorabiliaProductType.Memorabilia
    )
    .sort((a, b) => (b?.price || 0) - (a?.price || 0))[0]?.id;

  const athleteProfileMemorabilia =
    memorabiliaData?.getStoreMemorabilia.entities?.filter(
      (item) =>
        item.memorabiliaProductType === MemorabiliaProductType.Memorabilia &&
        item.id !== mostExpensiveItemId
    ) || [];

  const memorabilia = shouldHideMostExpensiveItem
    ? athleteProfileMemorabilia
    : memorabiliaData?.getStoreMemorabilia.entities?.filter(
        (item) =>
          item.memorabiliaProductType === MemorabiliaProductType.Memorabilia
      ) || [];

  const products =
    memorabiliaData?.getStoreMemorabilia?.entities?.filter(
      ({ memorabiliaProductType }) => {
        return memorabiliaProductType === MemorabiliaProductType.Product;
      }
    ) || [];

  const experiences = experienceData?.getExperiences?.entities || [];

  const isEmptyList = Boolean(
    !merchLoading &&
      merchData &&
      !merch.length &&
      !memorabiliaLoading &&
      memorabiliaData &&
      !memorabilia.length &&
      !products.length &&
      !experienceLoading &&
      !experiences.length
  );

  const memorabiliaLength: number = showOnlyMemorabilia
    ? memorabilia && memorabilia.length
    : showOnlyProducts
    ? products && products.length
    : 0;

  const showInfiniteCarousel = showOnlyMerch
    ? merch.length > 4
    : showOnlyMemorabilia
    ? memorabiliaLength > 4
    : merch.length + memorabilia.length + products.length > 4;

  const renderMerch = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows
          theme={theme}
          settings={{ infinite: showInfiniteCarousel }}
          hideArrows={merch.length < hideArrowCount}
        >
          {merch.map((merch) => (
            <RelatedMerchItem
              key={merch.id}
              merch={merch}
              openInNewTab
              imageLazyLoading
              type="default"
              theme={theme}
            />
          ))}
        </CarouselWithArrows>
      </div>
    );
  };

  const renderMemorabilia = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows
          theme={theme}
          settings={{ infinite: showInfiniteCarousel }}
          hideArrows={memorabiliaLength < hideArrowCount}
        >
          {memorabilia?.map((item) => (
            <RelatedMemorabiliaItem
              key={item.id}
              memorabilia={item}
              itemType="default"
              openInNewTab
              imageLazyLoading
              theme={theme}
            />
          ))}
        </CarouselWithArrows>
      </div>
    );
  };

  const renderExperience = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows
          theme={theme}
          settings={{ infinite: showInfiniteCarousel }}
          hideArrows={merch.length < hideArrowCount}
        >
          {experiences.map((experience) => (
            <RelatedExperienceItem
              key={experience.id}
              experience={experience}
              itemType="default"
              openInNewTab
              imageLazyLoading
              theme={theme}
            />
          ))}
        </CarouselWithArrows>
      </div>
    );
  };

  const renderAll = () => {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows
          theme={theme}
          settings={{ infinite: showInfiniteCarousel }}
          hideArrows={
            memorabiliaLength + products.length + merch.length < hideArrowCount
          }
        >
          {memorabilia?.map((memorabilia) => (
            <RelatedMemorabiliaItem
              key={memorabilia.id}
              memorabilia={memorabilia}
              itemType="default"
              openInNewTab
              imageLazyLoading
              theme={theme}
            />
          ))}
          {products.map((product) => (
            <RelatedMemorabiliaItem
              key={product.id}
              memorabilia={product}
              itemType="default"
              openInNewTab
              imageLazyLoading
              theme={theme}
            />
          ))}
          {merch.map((merch) => (
            <RelatedMerchItem
              key={merch.id}
              merch={merch}
              openInNewTab
              imageLazyLoading
              type="default"
              theme={theme}
            />
          ))}
        </CarouselWithArrows>
      </div>
    );
  };

  const renderCarousel = () => {
    if (showOnlyMemorabilia || showOnlyProducts) {
      return renderMemorabilia();
    }
    if (showOnlyMerch) {
      return renderMerch();
    }
    if (showOnlyExperience) {
      return renderExperience();
    }
    if (!showOnlyMerch && !showOnlyMemorabilia && !showOnlyExperience) {
      return renderAll();
    }
  };

  if (isEmptyList) {
    return null;
  }

  return (
    <div className={className}>
      <Loader loading={merchLoading || memorabiliaLoading} size="large" />
      {title && <h2 className={styles.title}>{title}</h2>}
      {renderCarousel()}
    </div>
  );
};

export default RelatedProductsListPendingStore;
