// Types
import { GetMediaPosts_getMediaPosts_entities } from 'api/mediaPost/types/GetMediaPosts';
// Components
import MediaFeedListItem from './MediaFeedListItem/MediaFeedListItem';
// Styles
import styles from './MediaFeedList.module.scss';

type MediaFeedListProps = {
  mediaFeeds: GetMediaPosts_getMediaPosts_entities[];
};

const MediaFeedList = ({ mediaFeeds }: MediaFeedListProps) => {
  return (
    <ul className={styles.root}>
      {mediaFeeds.map((item: GetMediaPosts_getMediaPosts_entities) => (
        <li className={styles.listItem} key={item.id}>
          <MediaFeedListItem mediaFeed={item} />
        </li>
      ))}
    </ul>
  );
};

export default MediaFeedList;
