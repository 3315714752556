import cn from 'classnames';
// Types
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { MemorabiliaProductType, UserRole } from 'api/graphql-global-types';
import { GetMemorabiliaMinimalInfo_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
// Constants
import { PRODUCTS } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Ui2
import Text from 'ui2/Text/Text';
import LinkButton from 'ui2/LinkButton/LinkButton';
// Components
import TopSection from 'components/common2/TopSection/TopSection';
// Helpers
import { formatPrice } from 'helpers/formatPrice';

import styles from './Memorabilia.module.scss';

type MemorabiliaProps = {
  store: GetStoreBySlug_store;
  memorabilia?: GetMemorabiliaMinimalInfo_getMemorabilia_entities | null;
  isPage?: boolean;
};

const Memorabilia = ({ store, memorabilia, isPage }: MemorabiliaProps) => {
  const { storeDetails } = store;

  const storeName = storeDetails?.storeName || '';

  const memorabiliaTitle = memorabilia?.title || '';
  const description = memorabilia?.description || '';
  const imageFileUrl =
    memorabilia?.images?.find(({ isMainImage }) => isMainImage)?.url || '';
  const price = memorabilia?.price || 0;
  const memorabiliaUrl = memorabilia?.slug || '';
  const storeSlug = memorabilia?.store.slug || '';

  const isAthlete = store.role === UserRole.Athlete;
  const isOrganization = store.role === UserRole.Organization;
  const isContentCreator = store.role === UserRole.ContentCreator;

  const pageUrl = isAthlete
    ? `${PRODUCTS}?athleteIds=${store.id}`
    : isOrganization
    ? `${PRODUCTS}?organizationIds=${store.id}`
    : isContentCreator
    ? `${PRODUCTS}?contentCreatorIds=${store.id}`
    : '';

  const memorabiliaProductType = memorabilia?.memorabiliaProductType || '';

  return (
    <TopSection
      backgroundTitle={memorabiliaProductType}
      className={styles.memorabiliaTopSection}
      backgroundTitleColor="white"
    >
      <div className={styles.root}>
        <section
          className={cn(styles.imageAndTitleWrapper, {
            [styles.adjustedPosition]: !isPage,
          })}
        >
          <h1 className={styles.title}>Memorabilia</h1>

          <div>
            <Image
              className={styles.image}
              src={imageFileUrl}
              alt={storeName}
              objectFit="cover"
              objectPosition="center top"
              priority
              sizes="(max-width: 1024px) 100vw, 50vw"
            />
          </div>
        </section>

        <section className={styles.contentWrapper}>
          <span className={styles.contentTitle}>{memorabiliaTitle}</span>

          <Text color="white">{description}</Text>

          <div className={styles.buttonsWrapper}>
            <LinkButton
              target="_blank"
              rel="noreferrer"
              xs="small"
              l="default"
              href={`/${storeSlug}/memorabilia/${memorabiliaUrl}`}
              color="harvest-gold"
              className={styles.linkButton}
            >
              view - {formatPrice(price)}
            </LinkButton>

            <LinkButton
              target="_blank"
              rel="noreferrer"
              xs="small"
              l="default"
              href={pageUrl}
              color="white"
              className={styles.linkButton}
            >
              {`see all my ${
                memorabiliaProductType === MemorabiliaProductType.Memorabilia
                  ? 'memorabilia'
                  : 'products'
              }`}
            </LinkButton>
          </div>
        </section>
      </div>
    </TopSection>
  );
};

export default Memorabilia;
