import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';
// Types
import { GetMediaPosts_getMediaPosts_entities } from 'api/mediaPost/types/GetMediaPosts';
// Constants
import { ATHLETES, SOCIAL_MEDIA_POST } from 'constants/routes';
// Helpers
import { getStreamerName } from 'helpers/streams';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './MediaFeedListItem.module.scss';

type MediaFeedListItemProps = {
  mediaFeed: GetMediaPosts_getMediaPosts_entities;
  theme?: Theme;
};

const MediaFeedListItem = ({
  mediaFeed,
  theme = 'white',
}: MediaFeedListItemProps) => {
  const router = useRouter();

  const mediaFeedSlug = mediaFeed.slug;
  const storeSlug = mediaFeed.store?.slug;

  const link = `${storeSlug}${SOCIAL_MEDIA_POST}${mediaFeedSlug}`;
  const storeName = getStreamerName(mediaFeed?.store);

  const handleStoreButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const url = storeSlug ? `/${storeSlug}` : ATHLETES;

    router.push(url);
  };

  const isVideo = mediaFeed && 'video' in mediaFeed;

  const thumbnailImage = isVideo
    ? mediaFeed.thumbnailUrl || mediaFeed.video.thumbnailUrl || ''
    : mediaFeed.image.url || '';

  const buttonText = isVideo ? 'Watch' : 'View';

  return (
    <>
      <Link href={link || ''} prefetch={false}>
        <a
          className={cn(styles.root, styles.default, {
            [styles[`theme-${theme}`]]: theme,
          })}
          title={`${mediaFeed.title} by ${storeName}`}
        >
          <div className={cn(styles.imageWrapper)}>
            <Image
              src={thumbnailImage || ''}
              alt={mediaFeed.title}
              layout="fill"
              objectFit="cover"
              objectPosition="center center"
              sizes="(max-width: 991px) 50vw, 30vw"
            />
          </div>
        </a>
      </Link>
      <div className={styles.infoWrapper}>
        <button className={styles.storeButton} onClick={handleStoreButtonClick}>
          {storeName}
        </button>

        <p className={cn(styles.title, styles.large)}>{mediaFeed.title}</p>
        <Link href={link || ''} prefetch={false}>
          <a>
            <div className={styles.infoFooter}>
              <p className={styles.viewMoreLabel}>{buttonText}</p>
            </div>
          </a>
        </Link>
      </div>
    </>
  );
};

export default MediaFeedListItem;
