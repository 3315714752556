// API
import { STREAMS } from 'constants/routes';
// Types
import { StreamStatus, UserRole } from 'api/graphql-global-types';
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
// Hooks
import {
  useGetCurrUser,
  useAthleteRelatedStreams,
  useBookFreeStream,
} from 'hooks';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Common
import Section from 'components/common2/Section/Section';
// Components
import FeaturedStream from './FeaturedStream/FeaturedStream';
import RelatedStreams from './RelatedStreams/RelatedStreams';

type StreamsProps = {
  storeId: string;
  storeSlug: string | null;
  storeRole: UserRole;
  className?: string;
  link?: string;
};

const Streams = ({
  storeId,
  storeSlug,
  storeRole,
  className,
  link,
}: StreamsProps) => {
  const { data: userData } = useGetCurrUser();

  const bookFreeStream = useBookFreeStream();

  const { streams, total } = useAthleteRelatedStreams({ storeId });

  const showRelatedStreams = total > 5;

  const getLinkText = (
    stream:
      | GetAthleteStreams_liveAndUpcomingStreams_entities
      | GetAthleteStreams_pastStreams_entities
  ): string => {
    const isUsersStream = userData?.me?.id === stream?.store?.id;
    const isPassedStream =
      stream.streamStatus === StreamStatus.Ended ? 'Past' : '';
    const streamText = `Purchase ${isPassedStream} Stream - ${formatCurrencyString(
      stream.price
    )}`;

    return stream.isPurchased || isUsersStream
      ? 'Watch this Stream'
      : stream.isFree
      ? 'Watch Video'
      : streamText;
  };

  const [featuredStream, ...relatedStreams] = streams;

  const handleStreamLinkClick = (
    stream:
      | GetAthleteStreams_liveAndUpcomingStreams_entities
      | GetAthleteStreams_pastStreams_entities,
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    bookFreeStream(stream);
  };

  const seeAllLink = link
    ? link
    : storeSlug && storeRole === UserRole.Athlete
    ? `${STREAMS}?athlete=${storeSlug}`
    : storeId && storeRole === UserRole.Organization
    ? `${STREAMS}?organizationIds=${storeId}`
    : storeRole === UserRole.ContentCreator
    ? `${STREAMS}?contentCreatorIds=${storeId}`
    : STREAMS;

  if (!streams.length) {
    return null;
  }

  return (
    <Section
      title="Streams"
      backgroundTitle="streams"
      seeAllLink={seeAllLink}
      seeAllLinkTitle="view more"
      className={className}
    >
      <FeaturedStream
        stream={featuredStream}
        linkText={getLinkText(featuredStream)}
        onLinkClick={handleStreamLinkClick}
      />
      {showRelatedStreams && <RelatedStreams streams={relatedStreams} />}
    </Section>
  );
};

export default Streams;
