import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
// Api
import { GET_STORE_BY_SLUG } from 'api/store/queries';
// Types
import { NextPage } from 'next';
import {
  GetStoreBySlug,
  GetStoreBySlugVariables,
} from 'api/store/types/GetStoreBySlug';
import { StoreStatus } from 'api/graphql-global-types';
// Hocks
import withApollo from 'hocs/withApollo';
// Hooks
import { useRedirectOnError } from 'hooks';
// Components
import AthleteContainer from 'components/Athlete/AthleteContainer/AthleteContainer';
import PendingAthleteContainer from 'components/Athlete/PendingAthleteContainer/PendingAthleteContainer';

const RESERVED_FILE_NAMES_REGEXP = /\.css\.map|favicon\.ico|apple-app-site-association|apple-touch-icon-precomposed\.png|apple-touch-icon\.png|apple-touch-icon-120x120-precomposed\.png|\.json/;

const Athlete: NextPage = () => {
  const router = useRouter();
  const { athleteSlug } = router.query;
  const skip = RESERVED_FILE_NAMES_REGEXP.test(`${athleteSlug}`);

  // clean tracking URL params
  const cleanedSlug = `${athleteSlug}`?.includes('?')
    ? `${athleteSlug}`.split('?')?.[0]
    : athleteSlug;

  const { data, error } = useQuery<GetStoreBySlug, GetStoreBySlugVariables>(
    GET_STORE_BY_SLUG,
    {
      variables: {
        input: {
          slug: cleanedSlug as string,
        },
      },
      skip,
    }
  );

  const isAthletePending = data?.store?.status === StoreStatus.Pending;

  useRedirectOnError({ error, redirectTo: '/' });

  return isAthletePending ? (
    <PendingAthleteContainer store={data?.store} />
  ) : (
    <AthleteContainer store={data?.store} />
  );
};

export default withApollo(Athlete, { getDataFromTree });
