// Types
import { GetExperiences_getExperiences_entities } from 'api/experiences/types/GetExperiences';
import { GetExperiencesMinimalInfo_getExperiences_entities } from 'api/experiences/types/GetExperiencesMinimalInfo';
// Components
import ExperienceListItem from './components/ExperienceListItem/ExperienceListItem';
import CarouselWithArrows from 'components/HomePage/components/common/CarouselWithArrows/CarouselWithArrows';

import styles from './ExperienceList.module.scss';

export type TypeMerchList = 'carousel' | 'store' | 'list' | 'ad';

type ExperienceListProps = {
  experiences:
    | GetExperiences_getExperiences_entities[]
    | GetExperiencesMinimalInfo_getExperiences_entities[];
  withCarousel?: boolean;
  itemType: TypeMerchList;
};

type ExperienceItem = GetExperiences_getExperiences_entities;

const ExperienceList = ({
  experiences,
  withCarousel,
  itemType,
}: ExperienceListProps) => {
  if (withCarousel) {
    return (
      <CarouselWithArrows>
        {experiences.map((experience: ExperienceItem) => (
          <ExperienceListItem
            key={experience.id}
            experience={experience}
            type="carousel"
          />
        ))}
      </CarouselWithArrows>
    );
  }

  return (
    <ul className={styles.root}>
      {experiences.map((experience: ExperienceItem, index: number) => (
        <li className={styles.listItem} key={experience.id}>
          <ExperienceListItem
            experience={experience}
            priorityImageLoading={index <= 2}
            type={itemType}
          />
        </li>
      ))}
    </ul>
  );
};

export default ExperienceList;
